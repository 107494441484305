
.painting-loading-wrapper {
  max-height: 85%;
  min-height: 85%;
  max-width: 100%;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .painting-loading-wrapper {
    min-height: 250px;
  }
}